import React from "react";

import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";

import Heading from "../../../components/shared/heading";
import Text from "../../../components/shared/text";

import { AboutSectionWrap, SectionTitle } from "./about.stc";

const About = ({
    HeadingStyle,
    TextStyle,
    ProjectTypeHeading,
    ProjectTypeText
}) => {
    const aboutQueryData = useStaticQuery(graphql`
        query AboutDataQuery {
            aboutdataJson(id: { eq: "about-intro-content" }) {
                id
                title
                desc
                content
                featured_work {
                    id
                    title
                    achivments
                }
            }
        }
    `);
    const { title, desc, featured_work } = aboutQueryData.aboutdataJson;
    return (
        <AboutSectionWrap>
            <div className="row">
                {(title || desc) && (
                    <div className="col-4 offset-1">
                        <SectionTitle>
                            {title && (
                                <Heading {...HeadingStyle}>{title}</Heading>
                            )}
                            {desc && <Text {...TextStyle}>{desc}</Text>}
                        </SectionTitle>
                    </div>
                )}
            </div>
            {featured_work && (
                <div className="row section-pt-md">
                    <div className="col-4 offset-1">
                        <div className="content row">
                            <ul>
                                <li>
                                    <a
                                        href="https://www.scrum.org/user/394939"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Scrum.org - Profressional Scrum Master
                                        level II (PSMII)
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.scrum.org/user/394939"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Scrum.org - Professional Scrum Master
                                        level I (PSMI)
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youracclaim.com/badges/8b4014d3-6225-435c-af34-5ae87d1a3cb9"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        APMG International - AgilePM® Foundation
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://skillshop.exceedlms.com/student/award/43538010"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Google - Ads Search Certification
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://skillshop.exceedlms.com/student/award/43538667"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Google - Analytics Individual
                                        Qualification
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://learndigital.withgoogle.com/link/1qsdpcedm9s"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Google - Google Digital Garage - #T2M
                                        NUL Q79
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://skillshop.exceedlms.com/profiles/0f2ab92856274a44aecc5baf2b3b07d3"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Google - Mobile Sites Certification
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://skillshop.exceedlms.com/student/award/43539375"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Google - Shopping ads Certification
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://hubspot-academy.s3.amazonaws.com/prod/tracks/user-certificates/e805622abb144decb04d21c86b24a61f-1577290264359.png"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        HubSpot - Content Marketing Certified
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://hubspot-academy.s3.amazonaws.com/prod/tracks/user-certificates/a67bce1b8387410ba1672a8410fb5ddb-1577265124024.png"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        HubSpot - Inbound Certified
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://hubspot-academy.s3.amazonaws.com/prod/tracks/user-certificates/47bf1fcfd45445cbbebfc944a78641f5-1577266908658.png"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        HubSpot - Social Media Certified
                                    </a>
                                </li>
                                <li>Shopify - Partner Business Certified</li>
                                <li>Shopify - Partner Product Certified</li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </AboutSectionWrap>
    );
};

About.propTypes = {
    HeadingStyle: PropTypes.object,
    TextStyle: PropTypes.object
};

About.defaultProps = {
    HeadingStyle: {
        fontSize: "40px",
        texttransform: "capitalize",
        lineHeight: "55px",
        color: "primary",
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: "24px",
                lineHeight: "initial"
            },
            small: {
                fontSize: "25px",
                lineHeight: "35px"
            }
        }
    },
    TextStyle: {
        lineHeight: "26px"
    },
    ProjectTypeHeading: {
        as: "h6",
        color: "primary",
        fontSize: "16px",
        fontweight: 700,
        letterspacing: "2px",
        mb: "16px"
    },
    ProjectTypeText: {
        m: 0,
        fontSize: "16px",
        color: "primary",
        letterspacing: "1px"
    }
};

export default About;
